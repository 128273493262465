.googleSingIn {
    margin-top: 40px;
    border-top: 1px solid rgba(0,0,0,.08);
    padding-top: 40px;
    position: relative;
    &:before {
        content: "OR";
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translate3d(-50%,-50%,0);
        transform: translate3d(-50%,-50%,0);
        background-color: #f9f9fa;
        padding: 0 2px;
        color: #c1c1c1;
        font-size: 14px;
        font-weight: 500;
    }
}