.meta {
    display: flex;
    justify-content: space-between;
    align-items: start;
}
.metaItem {
    margin-right: 12px;
    align-items: center;
    svg {
        margin-right: 8px;
    }
}